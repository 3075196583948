<template>
  <div>
    <div>
      <v-card>
        <div class="pa-5 mb-5">
          <v-card-title
            primary-title
            class="title"
          >
            Validation des dons
          </v-card-title>
          <div v-if="isUserNational">
            <v-alert
              v-if="!currentCdpeId"
              color="primary"
              dense
              type="info"
            >
              Veuillez sélectionner un
              <strong>Département</strong>
            </v-alert>
            <div class="text-right">
              <v-autocomplete
                :items="filterDepartements"
                label="Département"
                :item-text="item => `${item.code} - ${item.nom}`"
                item-value="id"
                @change="changeDepartement"
              />
            </div>
          </div>
        </div>
      </v-card>
    </div>
    <div>
      <div>
        <v-card>
          <div class="pa-5 mb-5">
            <v-card-title
              class="title"
              primary-title
            >
              Confirmation d'envoi
            </v-card-title>
            <div class="mb-5">
              <v-row>
                <v-col>Montant total :</v-col>
                <v-col>{{ amountToSend | currency }}</v-col>
              </v-row>
            </div>
            <v-btn
              color="primary"
              :disabled="canNotBeSend"
              @click="confirmValidateDons"
            >
              Valider les dons
            </v-btn>
          </div>
        </v-card>
      </div>
    </div>

    <div>
      <v-data-table
        v-model="selected"
        light
        :headers="headers"
        :items="validatedDons"
        item-key="key"
        show-select
        class="elevation-3"
        :loading="loading"
        loading-text="Chargement en cours"
        no-data-text="Aucun dons à valider"
        :items-per-page="50"
        :footer-props="{
          itemsPerPageText: 'Dons par page:',
          itemsPerPageOptions: [20, 50, 100, 200],
        }"
      >
        <v-progress-linear
          v-if="loading"
          v-slot:progress
          color="blue"
        />

        <template v-slot:no-data>
          Aucun dons à valider
        </template>

        <template v-slot:header.data-table-select>
          <v-checkbox
            :input-value="selectAllValue"
            :indeterminate="selectAllIndeterminate"
            @click.stop="toggleAll"
          />
        </template>
        <template v-slot:item.date="{ item }">
          {{ item.date | formatDate }}
        </template>
        <template v-slot:item.montant="{ item }">
          {{ item.montant | currency }}
        </template>
      </v-data-table>

      <v-progress-linear
        v-if="loading"
        :indeterminate="true"
      />
    </div>
    <Downloader
      :downloading-file="downloadingFile"
      label="bordereau de validation"
      @downloadSuccess="onDownloadSuccess"
    />
    <ConfirmedModal
      v-if="dialog"
      :sentence="
        'Êtes-vous sûr de vouloir valider ce' +
          (selected.length > 1 ? `s ${selected.length}` : '') +
          ' don' +
          (selected.length > 1 ? 's' : '') +
          ' pour un montant total de ' +
          this.$options.filters.currency(amountToSend) +
          ' ?'
      "
      btn-action="Valider"
      @close="dialog = false"
      @confirmed="validateDons()"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import selectAllMixin from '../../mixins/vTableDataSelectAll';
import Downloader from '../partials/Downloader';
import ConfirmedModal from '../reusableComponents/confirmedModal';

export default {
  name: 'ValidationDons',
  components: {
    Downloader,
    ConfirmedModal,
  },
  mixins: [selectAllMixin],
  data: () => ({
    currentCdpeId: 0,
    dialog: false,
    downloadingFile: null,
    headers: [
      {
        text: 'Nom',
        sortable: true,
        value: 'nom',
      },
      {
        text: 'Prenom',
        sortable: true,
        value: 'prenom',
      },
      {
        text: 'Date',
        sortable: true,
        value: 'date',
      },
      {
        text: 'Montant',
        sortable: true,
        value: 'montant',
      },
      {
        text: 'Paiement',
        sortable: true,
        value: 'moyenPaiement',
      },
      {
        text: 'Conseil Local Principal',
        sortable: true,
        value: 'cpe_principal',
      },
      {
        text: 'Statut Juridique',
        sortable: true,
        value: 'statut_juridique',
      },
    ],
  }),
  computed: {
    ...mapGetters('session', {
      isUserNational: 'isUserNational',
    }),
    ...mapGetters('cdpe', {
      filterDepartements: 'getFilterDepartements',
    }),
    ...mapState('workflows/dons', {
      cdpeId: 'cdpeId',
      loading: 'loading',
    }),
    ...mapGetters('workflows/dons', {
      validatedDons: 'getValidationDons',
    }),
    total() {
      return (this.selected || []).reduce((total, item) => total + item.montant, 0);
    },
    amountToSend() {
      return this.total;
    },
    canNotBeSend() {
      return (this.selected || []).length === 0;
    },

    items() {
      return this.validatedDons;
    },
  },

  methods: {
    ...mapActions('workflows/dons', {
      loadValidationDons: 'loadValidationDons',
      sendValidationDons: 'sendValidationDons',
    }),
    confirmValidateDons() {
      this.dialog = true;
    },
    validateDons() {
      this.dialog = false;
      this.sendValidationDons({
        donsId: this.selected.map(parent => ({
          id: parent.don_id,
        })),
      }).then(response => {
        let paramsUrl = {
          listLotId: response.lots.map(e => e.id),
        };

        this.selected = [];

        this.downloadingFile = {
          url: '/recudons',
          params: paramsUrl,
        };
      });
    },
    onDownloadSuccess() {
      this.loadValidationDons({
        cdpeId: this.cdpeId,
      });
    },
    changeDepartement(cdpeId) {
      this.currentCdpeId = cdpeId;
      this.loadValidationDons({ cdpeId });
    },
  },
};
</script>
